import { WmsService, LsfService } from '@/core/services/ApiService';

import Clients from '@/core/entity/Client';

async function getOrderFulfillmentByOrderNumber(orderNumber: string, systemId: number, clientId: number) {
  return await WmsService.query(`order-fulfillment/order`, {
    params: { 'order-number': orderNumber, 'system-id': systemId, 'client-id': clientId }
  });
}

async function getOrderFulfillmentByOutboundParcelId(outbound_parcel_id: number) {
  return await WmsService.get('order-fulfillment/outbound-parcel', `${outbound_parcel_id}`);
}

// 미사용. 피킹에서 처리현황 안보여주기 때문
async function getPickingOrderAssetCount() {
  const clients = new Clients();
  await clients.load();

  const params = {
    size: 100,
    // 'of-statuses': this.ofStatuses,
    'of-statuses': 'PICKING',
    'search-value': '',
    'search-type': '',
    'search-date-type': 'ORDER_AT',
    'start-search-date': '',
    'end-search-date': '',
    'client-ids': clients.clients.map((obj) => obj.id).join(',')
  };
  return await LsfService.query(`orders/client/${1}`, { params: params });
}

export default {
  getOrderFulfillmentByOrderNumber,
  getOrderFulfillmentByOutboundParcelId,
  getPickingOrderAssetCount
};
