import { AuthUser } from '@/core/api';
import { utcDateTime } from '@/core/helpers/format';
import { Mutations } from '@/store/enums/StoreEnums';
import router from '@/router';
import store from '@/store';
import Alert from '@/core/helpers/alert';

// SET VARIABLES
const accessCookieName = 'ACCESS_TOKEN';
const refreshCookieName = 'REFRESH_TOKEN';

function saveCookie(cookieName, cookieValue, expires) {
  const domain = process.env.VUE_APP_DOMAIN;
  if (process.env.VUE_APP_DOMAIN === 'PRODUCTION') {
    document.cookie = `${cookieName}=${cookieValue};expires=${expires};path=/;domain=${domain};secure;`;
  } else {
    document.cookie = `${cookieName}=${cookieValue};expires=${expires};path=/;`;
  }
}

function getCookie(cookieName) {
  const match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
  if (match) {
    return match[2];
  } else {
    return false;
  }
}

function destroyCookie(cookieName) {
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
}

interface SignInForm {
  sign_in_id: string;
  password: string;
}

async function getUserInfo() {
  const access_token = getCookie(accessCookieName);
  if (!access_token) {
    return { data: null, success: false };
  }

  try {
    const { data, success } = await AuthUser.validateToken(access_token);
    if (success && data) {
      const userdata = {
        user: {
          name: data.user.name,
          email: data.user.email,
          sign_in_id: data.user.sign_in_id
        },
        client: {
          id: data.user.client_id,
          code: data.user.client_code,
          name: data.user.client_name
        },
        auths: data.user.user_auths,
        tokens: {
          access_token: access_token,
          refresh_token: getCookie(refreshCookieName) || ''
        }
      };
      return { data: userdata, success: true };
    }
    return { data: null, success: false };
  } catch (error) {
    return { data: null, success: false };
  }
}

async function sessionSignIn(form: SignInForm) {
  const { data, success } = await AuthUser.signIn(form);
  if (success && data) {
    const userdata = {
      user: {
        name: data.user.name,
        email: data.user.email,
        sign_in_id: data.user.sign_in_id
      },
      client: {
        id: data.user.client_id,
        code: data.user.client_code,
        name: data.user.client_name
      },
      auths: data.user.user_auths,
      tokens: {
        access_token: data.access_token.access_token,
        refresh_token: data.refresh_token.refresh_token
      }
    };

    // Save Userdata to Store.
    store.commit(Mutations.SET_USER, userdata);

    // Save to Cookie
    saveCookie(accessCookieName, data.access_token.access_token, utcDateTime(data.access_token.expired_at));
    saveCookie(refreshCookieName, data.refresh_token.refresh_token, utcDateTime(data.refresh_token.expired_at));

    return { data: userdata, success: true };
  }
  return { data: null, success: false };
}

async function sessionSignOut() {
  let result = false;
  const tokens = store.getters.sessionTokens;
  let access_token: string | null = tokens.access_token;
  if (!access_token) {
    const access_cookie = getCookie(accessCookieName);
    if (access_cookie) access_token = access_cookie;
  }
  if (access_token) {
    const { success } = await AuthUser.signOut(access_token);
    if (success) {
      destroyCookie(accessCookieName);
      destroyCookie(refreshCookieName);
      result = true;
    }
  } else {
    destroyCookie(accessCookieName);
    destroyCookie(refreshCookieName);
    result = true;
  }

  if (result) {
    Alert.showAlert('로그아웃', '정상적으로 로그아웃되었습니다!', 'success', true, 'swal');
  }
  return result;
}

async function validateSession() {
  let validationCheck = false;

  const tokens = store.getters.sessionTokens;
  let access_token: string | null = tokens.access_token;
  let refresh_token: string | null = tokens.refresh_token;

  // Load Tokens from Cookie
  if (!access_token || !refresh_token) {
    const access_cookie = getCookie(accessCookieName);
    if (access_cookie) access_token = access_cookie;
    const refresh_cookie = getCookie(refreshCookieName);
    if (refresh_cookie) refresh_token = refresh_cookie;
  }

  // Validate & Refresh Token
  if (access_token) {
    const validation = await AuthUser.validateToken(access_token);
    if (validation.success) {
      validationCheck = true;
    } else if (validation.code === 3002 && refresh_token) {
      const { data, success } = await AuthUser.refreshToken(refresh_token);
      if (success && data) {
        // Update Token Vars
        access_token = data.access_token.access_token;
        refresh_token = data.refresh_token.refresh_token;

        // Upate Cookies
        saveCookie(accessCookieName, data.access_token.access_token, utcDateTime(data.access_token.expired_at));
        saveCookie(refreshCookieName, data.refresh_token.refresh_token, utcDateTime(data.refresh_token.expired_at));
        validationCheck = true;
      }
    }
    if (validationCheck) {
      const { data, success } = await AuthUser.validateToken(access_token);
      if (success && data) {
        const userdata = {
          user: {
            name: data.user.name,
            email: data.user.email,
            sign_in_id: data.user.sign_in_id
          },
          client: {
            id: data.user.client_id,
            code: data.user.client_code,
            name: data.user.client_name
          },
          auths: data.user.user_auths,
          tokens: {
            access_token: access_token,
            refresh_token: refresh_token
          }
        };
        store.commit(Mutations.SET_USER, userdata);
      }
    } else {
      destroyCookie(accessCookieName);
      destroyCookie(refreshCookieName);

      // 현재 경로 저장
      if (router.currentRoute.value.name !== 'sign-in') {
        localStorage.setItem('lastVisitedPage', router.currentRoute.value.fullPath);
      }

      // Log Out
      router.push({
        name: 'sign-in',
        query: { returnUrl: router.currentRoute.value.fullPath }
      });
      // // Log Out
      // router.push({ name: 'sign-in' });
      // router.go(0);
    }
  }
  return validationCheck;
}

//사용자가 사용중일때는 세션 만료 시간을 늘릴 수 있도록 한다.
async function extendSession() {
  const tokens = store.getters.sessionTokens;
  let access_token: string | null = tokens.access_token;
  let refresh_token: string | null = tokens.refresh_token;

  // Load Tokens from Cookie
  if (!access_token || !refresh_token) {
    const access_cookie = getCookie(accessCookieName);
    if (access_cookie) access_token = access_cookie;
    const refresh_cookie = getCookie(refreshCookieName);
    if (refresh_cookie) refresh_token = refresh_cookie;
  }

  // Validate & Refresh Token
  if (access_token) {
    const validation = await AuthUser.ExtendTokenSession(access_token);
    return validation.success;
  }
}

export default { getCookie, sessionSignIn, sessionSignOut, validateSession, getUserInfo, extendSession };
export { SignInForm, accessCookieName, refreshCookieName };
