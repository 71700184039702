import AuthService, { accessCookieName, refreshCookieName } from '@/core/services/AuthService';
import type { SignInForm } from '@/core/services/AuthService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { checkAccess } from '@/core/helpers/access';

export interface UserAuth {
  id: number;
  system_id: number;
  system_name?: string;
  user_id: number;
  user_level: string;
  user_level_id: number;
}

export interface Tokens {
  access_token: string;
  refresh_token: string;
}

export interface User {
  name: string;
  email: string;
  sign_in_id: string;
}

interface Client {
  id: number;
  code: string;
  name: string;
  system_id: number;
}

export interface UserAuthInfo {
  user: User;
  client: Client;
  auths: UserAuth[];
  tokens: Tokens;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = {} as User;
  client = {} as Client;
  auths: UserAuth[] = [];
  tokens = {} as Tokens;

  get currentUser(): User {
    return this.user;
  }

  get userClient(): Client {
    return this.client;
  }

  get authAccess(): UserAuth[] {
    return this.auths;
  }

  get sessionTokens(): Tokens {
    return this.tokens;
  }

  get isAdmin(): boolean {
    return checkAccess([{ system_id: 1, user_level_ids: [1, 2] }]);
  }

  get isManager(): boolean {
    return checkAccess([{ system_id: 1, user_level_ids: [1, 2, 6, 7] }]);
  }

  get isStaff(): boolean {
    return checkAccess([{ system_id: 1, user_level_ids: [6, 7] }]);
  }

  @Mutation
  [Mutations.SET_USER](payload) {
    this.user = payload.user;
    this.client = payload.client;
    this.auths = payload.auths;
    this.tokens = payload.tokens;
  }

  @Mutation
  [Mutations.CLEAR_USER]() {
    this.user = {} as User;
    this.client = {} as Client;
    this.auths = [];
    this.tokens = {} as Tokens;
  }

  @Action
  [Actions.SIGN_IN](form: SignInForm) {
    const returnUrl = localStorage.getItem('lastVisitedPage') || '/';
    return AuthService.sessionSignIn(form).then(({ data, success }) => {
      if (success) {
        this.context.commit(Mutations.SET_USER, data);
        localStorage.removeItem('lastVisitedPage');
        return { success: true, returnUrl };
      }
      return { success: false, returnUrl: null };
    });
  }

  @Action
  [Actions.SIGN_OUT]() {
    return AuthService.sessionSignOut().then((success) => {
      if (success) this.context.commit(Mutations.CLEAR_USER);
    });
  }

  @Action
  async [Actions.CHECK_AUTH]() {
    const accessToken = AuthService.getCookie(accessCookieName);
    if (!accessToken) {
      return false;
    }

    // 토큰이 있으면서 user 정보가 없는 경우 새로고침으로 간주
    if (accessToken && !this.user?.sign_in_id) {
      try {
        // 토큰으로 사용자 정보를 다시 가져오는 API 호출
        const { data, success } = await AuthService.getUserInfo();
        if (success) {
          this.context.commit(Mutations.SET_USER, data);
          return true;
        }
      } catch (error) {
        return false;
      }
    }

    return !!this.user?.sign_in_id;
  }
}
